import React, { useCallback, useEffect, useState } from 'react';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Container, Stack, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import { useMessage } from '../../layouts/Header';
import CompleteInterview from './CompleteInterview';
import useLoader from '../../hooks/useLoader';
import { handleAxiosError } from '../../utils/function';
import axios from 'axios';

const Interview = () => {
    const [totalSeconds, setTotalSeconds] = useState(0);
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [interviewStopped, setinterviewStopped] = useState(false);

    console.log(interviewStopped);
    const [answers, setAnswers] = React.useState({});
    // const [content, setContent] = useState();
    const [isInterviewDone, setIsInterviewDone] = useState(false);
    const { pageloading, start, end } = useLoader({
        pageHeight: 'calc(100vh - 370px)',
        size: 46,
    });

    const [questions, setQuestions] = useState(null);
    const [helperText, setHelperText] = React.useState('');
    const { showSuccess, showError } = useMessage();

    const handleRadioChange = e => {
        const { value, name } = e.target;

        answers[name] = value;

        setAnswers({ ...answers });
        setHelperText('');
    };
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const startTimer = () => {
        setInterviewStarted(true);
    };

    useEffect(() => {
        let interval;

        if (interviewStarted) {
            interval = setInterval(() => {
                setTotalSeconds(prevTotalSeconds => prevTotalSeconds + 1);
            }, 1000); // Timer updates every 1 second
        } else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [interviewStarted]);

    const handleSubmit = e => {
        e.preventDefault();

        if (answers === null) return setHelperText('Please wait until questions are fetched');

        if (Object.values(answers).some(ans => !ans))
            return setHelperText('Please fill out all the answers');

        // if answers are all completed
        stopTimer();
        sendAnswers();
    };

    const getFormattedAnswers = useCallback(
        function () {
            const result = [];

            for (const id in answers) {
                const answer = answers[id];
                result.push({
                    questionId: id,
                    answer,
                });
            }

            return result;
        },
        [answers]
    );

    const fetchQuestions = useCallback(
        async function () {
            start();
            try {
                const response = await axios.get('/user/answer');

                const { questions, isInterviewDone } = response.data;
                console.log(isInterviewDone);
                if (isInterviewDone) {
                    return setIsInterviewDone(true);
                }

                const answers = {};
                questions.questions.forEach(question => (answers[question._id] = ''));

                setQuestions(questions.questions);
                setAnswers(answers);
            } catch (e) {
                console.log(e);
            } finally {
                end();
            }
        },
        [setAnswers, setQuestions, start, end]
    );
    const stopTimer = () => {
        setinterviewStopped(true);
        setInterviewStarted(false);
    };

    const sendAnswers = useCallback(
        async function () {
            try {
                const res = await axios.post('/user/answer', {
                    answers: getFormattedAnswers(),
                    timeTaken: `${hours.toString().padStart(2, '0')}:${minutes
                        .toString()
                        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
                });

                const { success } = res.data;

                if (success) {
                    showSuccess('Answer submited successfully');
                    fetchQuestions();
                }
            } catch (e) {
                handleAxiosError(e, showError);
            }
        },
        [showError, getFormattedAnswers, showSuccess, fetchQuestions, hours, minutes, seconds]
    );

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    return (
        <>
            {isInterviewDone ? (
                <CompleteInterview />
            ) : (
                <>
                    {questions ? (
                        <Box>
                            <form
                                onSubmit={handleSubmit}
                                style={{
                                    height: 'inherit',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <Container maxWidth='lg' sx={{ flexGrow: 1, px: { xs: 1, sm: 2 } }}>
                                    <Stack
                                        direction='horizontal'
                                        justifyContent='space-between'
                                        alignItems='center'>
                                        <Typography variant='h5'>Interview Question</Typography>
                                        <Box display='flex' alignItems='center'>
                                            <Typography
                                                fontWeight='bold'
                                                color='text.secondary'
                                                sx={{
                                                    background: 'white',
                                                    fontSize: '20px',
                                                    px: 1,
                                                    borderRadius: '10px',
                                                }}>
                                                {hours.toString().padStart(2, '0')}:
                                                {minutes.toString().padStart(2, '0')}:
                                                {seconds.toString().padStart(2, '0')}
                                            </Typography>
                                            <Button
                                                onClick={startTimer}
                                                sx={{ ml: 2 }}
                                                variant='contained'
                                                size='small'
                                                disabled={interviewStarted}>
                                                Start Interview
                                            </Button>
                                        </Box>
                                    </Stack>
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {helperText}
                                    </FormHelperText>
                                    {questions?.map((q, i) => {
                                        const answerNo = Object.keys(q.options);
                                        return (
                                            <Box my={2} key={i}>
                                                <Stack direction='horizontal' columnGap={1}>
                                                    <Typography
                                                        color='text.secondary'
                                                        whiteSpace='nowrap'>
                                                        Q {i + 1} :
                                                    </Typography>
                                                    <Typography>{q.question}</Typography>
                                                </Stack>
                                                <Box>
                                                    <FormControl
                                                        disabled={!interviewStarted}
                                                        sx={{
                                                            m: 3,
                                                            mt: 2,
                                                            ml: {
                                                                xs: 3,
                                                                sm: 6,
                                                            },
                                                        }}
                                                        variant='standard'>
                                                        <RadioGroup
                                                            aria-labelledby='demo-error-radios'
                                                            name={q._id}
                                                            value={answers[q._id]}
                                                            onChange={handleRadioChange}>
                                                            <FormControlLabel
                                                                value={answerNo[0]}
                                                                control={<Radio />}
                                                                label={q.options.a}
                                                            />
                                                            <FormControlLabel
                                                                value={answerNo[1]}
                                                                control={<Radio />}
                                                                label={q.options.b}
                                                            />
                                                            <FormControlLabel
                                                                value={answerNo[2]}
                                                                control={<Radio />}
                                                                label={q.options.c}
                                                            />
                                                            <FormControlLabel
                                                                value={answerNo[3]}
                                                                control={<Radio />}
                                                                label={q.options.d}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Container>

                                <Box mx={2.5} mb={2.5} textAlign='right'>
                                    <Button type='submit' variant='contained'>
                                        Complete interview
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    ) : (
                        <>{pageloading}</>
                    )}
                </>
            )}
        </>
    );
};

export default Interview;
