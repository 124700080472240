import React, { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMessage } from '../../layouts/Header';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import useErrorHandler from '../../hooks/useErrorHandler';

// const ALPHA_REGEX = /^[A-Za-z ]+$/;

const AddExpenses = props => {
    const { closeModal, refresh } = props;
    const { showSuccess, showError } = useMessage();
    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const errorHandler = useErrorHandler();
    const [expense, setExpense] = useState({
        title: '',
        purchasePlace: '',
        currency: '',
        amount: '',
        note: '',
    });

    const onChangeHandler = e => {
        const { name, value } = e.target;

        setExpense(prev => ({ ...prev, [name]: value }));
    };

    const fileHandler = async e => {
        const file = e.target.files[0];
        if (!file) return showError('No file selected');
        setFile(file);
    };

    console.log(date);

    const addExpenses = async e => {
        e.preventDefault();
        setLoading(true);

        expense.dateOfPurchase = {
            year: date.$y,
            month: date.$M,
            day: date.$D,
        };

        const formData = new FormData();
        formData.set('invoice', file);

        for (const key in expense) {
            if (expense[key] !== 'currency' && expense[key] !== 'amount')
                formData.set(key, expense[key]);
        }

        formData.set(`price[currency]`, expense.currency);
        formData.set(`price[amount]`, expense.amount);
        formData.set(`dateOfPurchase[year]`, date.$y);
        formData.set(`dateOfPurchase[month]`, date.$M);
        formData.set(`dateOfPurchase[day]`, date.$D);

        try {
            const response = await axios.post('/employee/expenses', formData);

            if (response.data.success) {
                showSuccess('Expense added successfully');
                refresh();
            }
        } catch (e) {
            errorHandler(e);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return (
        <Card
            sx={{
                overflow: 'auto',
                maxHeight: '90vh',

                '::-webkit-scrollbar': { display: 'none' },
                boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                borderRadius: '8px',
                maxWidth: '600px',
                width: '100%',
                p: 4,
            }}>
            <CardContent>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography variant='h6' fontWeight={500}>
                        Add expense
                    </Typography>

                    <IconButton onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <form onSubmit={addExpenses}>
                    <Typography variant='subtitle2' gutterBottom>
                        Title
                    </Typography>
                    <TextField
                        variant='outlined'
                        size='small'
                        name='title'
                        value={expense.title}
                        // onKeyDown={event => {
                        //     if (!ALPHA_REGEX.test(event.key)) {
                        //         event.preventDefault();
                        //     }
                        // }}
                        onChange={onChangeHandler}
                        fullWidth
                    />
                    <Typography variant='subtitle2' gutterBottom>
                        Purchase Place
                    </Typography>
                    <TextField
                        variant='outlined'
                        size='small'
                        // onKeyDown={event => {
                        //     if (!ALPHA_REGEX.test(event.key)) {
                        //         event.preventDefault();
                        //     }
                        // }}
                        name='purchasePlace'
                        value={expense.purchasePlace}
                        onChange={onChangeHandler}
                        fullWidth
                    />
                    <Typography variant='subtitle2' gutterBottom>
                        Price
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography variant='caption' gutterBottom>
                                Amount
                            </Typography>
                            <TextField
                                variant='outlined'
                                size='small'
                                name='amount'
                                type='number'
                                value={expense.amount}
                                onChange={onChangeHandler}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='caption' gutterBottom>
                                Currency
                            </Typography>
                            <TextField
                                variant='outlined'
                                size='small'
                                name='currency'
                                value={expense.currency.toUpperCase()}
                                onChange={onChangeHandler}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Typography variant='subtitle2' gutterBottom>
                        Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    fullWidth: true,
                                    variant: 'outlined',
                                },
                            }}
                            value={date}
                            onChange={value => setDate(value)}
                        />
                    </LocalizationProvider>
                    <Typography variant='subtitle2' gutterBottom>
                        Upload invoice
                    </Typography>
                    <TextField
                        variant='outlined'
                        size='small'
                        type='file'
                        fullWidth
                        onChange={fileHandler}
                    />
                    <Typography variant='subtitle2' gutterBottom>
                        Note:
                    </Typography>
                    <TextField
                        variant='outlined'
                        size='small'
                        name='note'
                        fullWidth
                        value={expense.note}
                        onChange={onChangeHandler}
                        rows={3}
                        multiline
                    />

                    <Button
                        type='submit'
                        variant='contained'
                        disabled={
                            loading ||
                            !(date && file && Object.values(expense).every(el => el !== ''))
                        }
                        endIcon={
                            loading && <CircularProgress size='20px' sx={{ color: 'inherit' }} />
                        }
                        sx={{ float: 'right', mb: 2 }}>
                        Add expenses{' '}
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default AddExpenses;
