import {
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import React from 'react';

const DashboardCard = (props) => {
    const { Title, Number, Icon, Color } = props;
    return (
        <>
            <Grid item lg={6} xl={3} xs={12}>
                <Card elevation={0}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Typography variant='body1' sx={{ mb: 2 }}>
                                {Title}
                            </Typography>
                            <Typography variant='h6' sx={{ color: Color }}>
                                {Number}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                sx={{
                                    p: 2,
                                    background: Color,
                                    color: '#fff',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        background: Color,
                                    },
                                }}
                            >
                                {/*  */}
                                {Icon}
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default DashboardCard;
