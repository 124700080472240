import React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Box, Card, CardContent, Typography } from '@mui/material';

const Calender = () => {
    return (
        <>
            <Card sx={{ pt: 1, position: 'relative' }} elevation={0}>
                <CardContent sx={{ fontSize: '14px', p: 0 }}>
                    <Box
                        sx={{
                            borderLeftWidth: '5px',
                            borderLeftColor: 'primary.main',
                            borderLeftStyle: 'solid',
                            height: '40px',
                            width: '5px',
                            position: 'absolute',

                            left: 1,
                        }}
                    ></Box>
                    <Typography variant='h6' sx={{ mb: 2, px: 2 }}>
                        Calender
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{}}>
                        <DemoContainer components={['StaticDatePicker']}>
                            <DemoItem>
                                <StaticDatePicker
                                    sx={{
                                        '& .MuiDialogActions-root': {
                                            display: 'none',
                                        },
                                        '::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                    defaultValue={dayjs('2022-04-17')}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </CardContent>
            </Card>
        </>
    );
};

export default Calender;
