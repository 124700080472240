import React, { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import axios from 'axios';
import { env } from '../utils/function';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCookie } from '../utils/cookies';

const authenticateContext = createContext();

const AuthenticationProvider = ({ children }) => {
    const [content, setContent] = useState(<Loading message='Please wait, logging you in...' />);
    const [user, setUser] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    // const [employees, setEmployees] = useState({});
    // const [employeeInfo, setEmployeeInfo] = useState([]);
    // const getDomain = url => url.replace(/(http:\/\/|https:\/\/)/, '');

    const authenticate = async (loggedIn, cb) => {
        if (loggedIn) {
            setContent(children);
        } else {
            const redirectTo =
                env('AUTHENTICATION_CLIENT') +
                '/login?redirectto=' +
                encodeURIComponent(env('DOMAIN') + location.pathname + location.search);

            setContent(
                <Loading
                    message='Please wait, redirecting you to Catch Accounts'
                    redirectTo={redirectTo}
                />
            );
        }
        if (typeof cb === 'function') cb(setUser);
    };

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const response = await axios.get(`/profile`);
    //             const user = response.data.user;
    //             console.log(user);

    //             authenticate(true, setUser => setUser(user));
    //         } catch (err) {
    //             console.log(err);
    //             authenticate(false);
    //         }
    //     })();
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const createSession = async (refreshToken, user) => {
        try {
            const response = await axios.post(`/open/session`, {
                refreshToken, userId :user._id , userType:"employee"
            });
            let data = response.data;
            if (data.success) {
                setCookie("accessToken", data.token);
                let temp = user 
                temp.role = data.user.role
                authenticate(true, (setUser) => setUser(temp));
     
                 if(temp.role !== "employee"){
                    navigate("/on-boarding")
                 }   
            } else {
                authenticate(false);
            }
        } catch (e) {
            authenticate(false);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const queryParameters = new URLSearchParams(window.location.search)
                const userId = queryParameters.get("userId")
                const refreshToken = queryParameters.get("refreshToken")
                console.log(userId);

                if (userId) {
                    var formData = new FormData();
                    formData.append("id", userId);
                    const response = await fetch(
                        "https://accounts.clikkle.com:5000/api/auth/get_user_profile",
                        // "https://api.campaigns.clikkle.com/get_user_profile",
                        // "http://localhost:5000/api/auth/get_user_profile",
                        {
                            method: "POST",
                            body: formData
                        },

                    );

                    if (response.ok) {
                        console.log('user found ...')
                        const responseData = await response.json();
                        let { user } = responseData;
                        user.refreshToken = refreshToken;
                        localStorage.setItem("user", JSON.stringify(user));
                        await createSession(refreshToken ,user );
                        console.log(user)
                       
                        // authenticate(true, (setUser) => setUser(user));
                    } else {
                        console.log('user not found')
                    }
                } else {
                    authenticate(false);
                }

            } catch (err) {
                console.log(err);
                // handleAxiosError(err, showError);
                authenticate(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <authenticateContext.Provider value={{ authenticate, setUser, user }}>
            {content}
        </authenticateContext.Provider>
    );
};

const useAuthenticate = () => useContext(authenticateContext).authenticate;
const useUser = () => useContext(authenticateContext).user;
const useSetUser = () => useContext(authenticateContext).setUser;

export default AuthenticationProvider;
export { useAuthenticate, useUser, useSetUser };
