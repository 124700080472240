import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { removeHtmlTags } from '../../utils/function';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Noticecard = ({ Textcolor, Background, title, caption, date }) => {
    return (
        <>
            <Grid container spacing={2} display='flex' alignItems='center'>
                <Grid item>
                    <IconButton
                        sx={{
                            px: 1.5,
                            py: 0.5,
                            backgroundColor: Background,
                            borderRadius: '8px',
                            color: Textcolor,
                            '&:hover': {
                                backgroundColor: Background,
                            },
                        }}>
                        <Box display='flex' flexDirection='column'>
                            <Typography textAlign='center' fontWeight='bold' variant='body1'>
                                {new Date(date).getDay()}
                            </Typography>
                            <Typography
                                variant='caption'
                                fontWeight='bold'
                                textTransform='uppercase'>
                                {months[new Date(date).getMonth()]}
                            </Typography>
                        </Box>
                    </IconButton>
                </Grid>
                <Grid item xs>
                    <Typography variant='body2' sx={{ fontSize: '12px' }}>
                        {title}
                    </Typography>
                    <Typography variant='caption' color='text.secondary'>
                        {removeHtmlTags(caption)}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Noticecard;
