import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Agreement from './Agreement';
import OfferLetter from './OfferLetter';
import Orientation from './Orientation';
import InitialStep from './InitialStep';
import useLoader from '../../hooks/useLoader';
import Interview from './Interview';
import axios from 'axios';

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <InterpreterModeIcon fontSize='small' />,
        2: <LocalPostOfficeOutlinedIcon fontSize='small' />,
        3: <HandshakeOutlinedIcon fontSize='small' />,
        4: <VideoLabelIcon fontSize='small' />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const pages = {
    0: <InitialStep />,
    1: <Interview />,
    2: <OfferLetter />,
    3: <Agreement />,
    4: <Orientation />,
};

const steps = ['Interview', 'Job Offer Letter', 'Agreements', 'Orientation'];

const OnBoarding = () => {
    const [step, setStep] = useState(0);
    const { pageloading, start, end } = useLoader({
        pageHeight: 'calc(100vh - 470px)',
        size: 46,
    });
    const [activeStep, setActiveStep] = useState(0);

    const getStep = useCallback(async () => {
        start();
        try {
            const response = await axios.get(`/user/on-boarding`);

            const step = response.data.step;

            setStep(step);
            setActiveStep(step);
        } catch (err) {
            console.log(err);
        } finally {
            end();
        }
    }, [setStep, start, end]);

    console.log({ step, activeStep });
    useEffect(() => {
        getStep();
    }, [getStep]);
    return (
        <>
            <Box display='flex' flexDirection='column' height='inherit'>
                <Box my={2} mb={7} textAlign='center'>
                    <Typography variant='h5' fontWeight='bold' color='text.secondary'>
                        On Boarding Procedure
                    </Typography>
                </Box>
                <Stepper
                    alternativeLabel
                    activeStep={step - 1}
                    connector={<ColorlibConnector />}
                    sx={{ mb: 5 }}>
                    {steps.map((label, i) => (
                        <Step
                            key={label}
                            onClick={() => (i < step ? setActiveStep(i + 1) : setActiveStep(step))}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {step === 0 && <Box flexGrow={1}>{pages[activeStep]}</Box>}
                {step ? <Box flexGrow={1}>{pages[activeStep]}</Box> : step !== 0 && pageloading}
            </Box>
        </>
    );
};

export default OnBoarding;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));
