import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import DataGrid from '../components/DataGrid';
import ApplyLeaves from './ApplyLeaves';
import useModal from '../hooks/useModal';
import { calcLeaveDays } from '../utils/function';

const columns = [
    {
        field: 'id',
        headerName: 'S.No.',
        sortable: false,
        width: 100,
        valueGetter: params => `${params.row.id + 1}`,
    },
    {
        field: 'leave',
        headerName: 'Leave Type',
        sortable: false,
        width: 200,
    },
    {
        field: 'from',
        headerName: 'From',
        type: 'date',
        align: 'left',
        width: 150,
        valueGetter: params => {
            const { year, month, day } = params.row.from;
            return new Date(year, month, day);
        },
    },
    {
        field: 'to',
        headerName: 'To',
        type: 'date',
        align: 'left',
        width: 150,
        valueGetter: params => {
            const { year, month, day } = params.row?.to;
            return new Date(year, month, day);
        },
    },
    {
        field: 'days',
        headerName: 'Days',
        width: 100,
    },
    {
        field: 'reason',
        headerName: 'Reason',
        width: 300,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
    },
];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [metrics, setMetrics] = useState([]);
    const { state, closeModal, openModal } = useModal();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);

    const fetchLeaves = useCallback(async () => {
        setLoading(true);

        console.log('fetching....');
        setRows([]);

        try {
            const response = await axios.get(
                `/employee/leaves/?page=${paginationModel.page + 1 || 1}&pageSize=${
                    paginationModel.pageSize
                }`
            );
            const { leaves, pageData } = response.data;

            console.log(leaves);

            const rows = leaves.map((leave, index) => ({
                ...leave,
                id: index,
                days:
                    leave.dates.length === 1 ? '1' : calcLeaveDays(leave.dates[0], leave.dates[1]),
                from: leave.dates[0],
                to: leave.dates.pop(),
            }));

            setRows(rows);
            setRowCount(pageData.totalData || 0);
        } catch (e) {
            console.warn(e);
        } finally {
            setLoading(false);
        }
    }, [setRows, paginationModel]);

    // const fetchMetrics = useCallback(async () => {
    //     try {
    //         const response = await axios.get('/employee/leaves/metrics');

    //         const leavesMetrics = response.data.leavesMetrics;
    //         const Metrics = [];

    //         for (let leave in leavesMetrics) {
    //             Metrics.push({ label: [leave], value: leavesMetrics[leave] });
    //         }

    //         setMetrics(Metrics);
    //     } catch (e) {
    //         console.warn(e);
    //     }
    // }, [setMetrics]);

    useEffect(() => {
        fetchLeaves();
    }, [fetchLeaves]);

    // useEffect(() => {
    //     fetchMetrics();
    // }, [fetchMetrics]);

    console.log(rows);
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>My Leaves</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box mx={2}>
                            <Button variant='contained' onClick={openModal}>
                                Apply Leaves
                            </Button>
                        </Box>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Card elevation={0} sx={{ position: 'relative', pt: 2, mt: 3 }}>
                <Box
                    sx={{
                        borderLeftWidth: '5px',
                        borderLeftColor: 'primary.main',
                        borderLeftStyle: 'solid',
                        height: '40px',
                        width: '5px',
                        position: 'absolute',

                        left: 1,
                    }}></Box>
                <Typography variant='h6' mx={2} mb={2}>
                    Leaves Summary
                </Typography>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode='server'
                    pageSizeOptions={[5, 10, 50, 100]}
                    autoHeight
                    disableSelectionOnClick
                    loading={loading}
                    rowCount={rowCount}
                />
            </Card>

            <Modal
                open={state}
                onClose={closeModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <ApplyLeaves handleClose={closeModal} fetchLeaves={fetchLeaves} />
                </>
            </Modal>
        </>
    );
};

export default Index;
