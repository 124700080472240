const link = path => process.env.REACT_APP_MAIN_SITE + path;

const env = name => {
    const nodeENV = process.env.NODE_ENV.toUpperCase();

    return process.env[`REACT_APP_${nodeENV}_${name}`] || process.env[`REACT_APP_${name}`];
};

let b64DecodeUnicode = str =>
    decodeURIComponent(
        Array.prototype.map
            .call(atob(str), c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );

let parseJwt = token => {
    console.log({ token });
    return JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')));
};

function escapeDanger(content) {
    const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim;

    if (regex.test(content)) return null;
    return content;
}

const handleAxiosError = (e, showError) => {
    console.log(e);
    const errors = e?.response?.data?.errors;
    const status = e?.response?.status;

    if (status === 500) return showError('Something went wrong');

    if (status === 400) return showError(errors || `Ensure you've entered valid information.`);

    if (status === 404) return showError(errors || `We can't find what you are looking for.`);

    if (e?.response?.data) {
        if (typeof errors === 'object' && errors !== null) showError(Object.values(errors));
        showError(errors || 'Our server encountered an error, Please try again later');
    } else {
        showError('Something went wrong');
    }
};

const getDomain = url => url.replace(/(http:\/\/|https:\/\/)/, '');

function dirname(filePath) {
    if (typeof filePath !== 'string') {
        throw new TypeError('Path must be a string');
    }

    const separator = '/';
    const lastIndex = filePath.lastIndexOf(separator);
    if (lastIndex === -1) {
        return '.';
    }

    return filePath.slice(0, lastIndex);
}

function basename(filePath, ext = '') {
    if (typeof filePath !== 'string') {
        throw new TypeError('Path must be a string');
    }

    const separator = '/';
    const lastIndex = filePath.lastIndexOf(separator);
    let baseName = filePath.slice(lastIndex + 1);

    if (ext && baseName.endsWith(ext)) {
        baseName = baseName.slice(0, -ext.length);
    }

    return baseName;
}

function parseKB(KB) {
    const sizes = ['KB', 'MB', 'GB', 'TB'];
    if (KB === 0) return '0 KB';
    const i = Math.floor(Math.log2(KB) / 10);
    return `${parseFloat((KB / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

const domain = path => 'https://' + path + '.' + process.env.REACT_APP_MAIN_SITE;

const isEmpty = obj => Object.keys(obj).length === 0;
const isObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

function removeHtmlTags(content) {
    const regex = /(<([^>]+)>)/gim;

    return content ? content.replace(regex, '') : content;
}

const calcLeaveDays = (start, end) => {
    const startDate = new Date(start.year, start.month, start.day);
    const endDate = new Date(end.year, end.month, end.day);

    let selectedDays = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        selectedDays++;
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return selectedDays;
};

function formatTimestamp(timestamp) {
    const now = new Date();
    const time = new Date(timestamp);
    const diffInMs = now - time;
    const diffInMinutes = Math.floor(diffInMs / 60000); // Convert milliseconds to minutes

    if (diffInMinutes < 1) {
        return 'now';
    } else if (diffInMinutes <= 5) {
        return `${diffInMinutes}m ago`;
    } else {
        // Format time to 9:30 PM
        const hours = time.getHours();
        const minutes = time.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        const formattedHours = hours % 12 || 12; // Convert hour '0' to '12'
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes} ${period}`;
    }
}


export {
    link,
    env,
    parseJwt,
    handleAxiosError,
    getDomain,
    basename,
    dirname,
    parseKB,
    domain,
    isEmpty,
    isObject,
    removeHtmlTags,
    calcLeaveDays,
    escapeDanger,
    formatTimestamp
};
