import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Images } from '../../components/Image';

const InitialStep = () => {
    return (
        <>
            <Grid
                container
                spacing={0}
                display='flex'
                alignItems='center'
                style={{ height: '45vh' }}
                justifyContent='center'
            >
                <Grid
                    item
                   
                    xs={11}
                    sx={{ textAlign:'center' , mb:1 }}
                    justifyContent='center'
                >
                    <Images src='interview1.svg' style={{ height: '250px' , margin:"auto" }} />
                </Grid>
                <Grid item  xs={11}>
                    <Typography sx={{ maxWidth: '700px', m: "auto" }} variant='h6'>
                “Patience is the key to success. Your journey begins with the initial step
                of the interview. Embrace this moment and prepare to shine. Your time is
                coming, so stay tuned!”
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default InitialStep;
