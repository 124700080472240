import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    CircularProgress,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import Editprofile from './Editprofile';
import moment from 'moment';
import Loading from '../../components/Loading';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from '@emotion/styled';
import { useMessage } from '../../layouts/Header';
import { useUser, useSetUser } from '../../hooks/Authenticate';
import BankDetails from './BankDetails';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Index = () => {
    const { showSuccess, showError } = useMessage();
    const [profileLoading, setProfileLoading] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const employeeDetail = useUser();
    const setEmployeeDetail = useSetUser();

    const updatePicture = useCallback(
        async function (newPicture) {
            setProfileLoading(true);
            try {
                const formData = new FormData();
                formData.append('photo', newPicture);

                const res = await axios.patch('/employee/profile/picture', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const { success, photo, message } = res.data;
                if (!success) return showError(message);

                setEmployeeDetail({ ...employeeDetail, photo });
                showSuccess('Update Profile Picture Successfully');
            } catch (e) {
                console.log(e);
            } finally {
                setProfileLoading(false);
            }
        },
        [showSuccess, employeeDetail, setEmployeeDetail, showError]
    );

    const handleChange = e => {
        updatePicture(e.target.files[0]);
    };

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Profile</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box sx={{ ml: 2 }}>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={5}>
                {employeeDetail ? (
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            <Card elevation={0}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                    {employeeDetail.photo ? (
                                        <Avatar
                                            alt='Remy Sharp'
                                            src={employeeDetail.localImage}
                                            sx={{
                                                width: 90,
                                                height: 90,

                                                my: 2,
                                            }}
                                        />
                                    ) : (
                                        <Box>
                                            <Avatar
                                                alt='Remy Sharp'
                                                src='https://cdn-icons-png.flaticon.com/512/149/149071.png'
                                                sx={{
                                                    cursor: 'pointer',

                                                    width: 90,
                                                    height: 90,

                                                    my: 2,
                                                    // borderRadius: '8px',
                                                }}
                                            />
                                        </Box>
                                    )}

                                    <Button
                                        type='submit'
                                        sx={{ mb: 2 }}
                                        size='small'
                                        component='label'
                                        variant='contained'
                                        disabled={profileLoading}
                                        startIcon={<CloudUploadIcon />}
                                        endIcon={
                                            profileLoading && (
                                                <CircularProgress
                                                    size='20px'
                                                    sx={{ color: 'inherit' }}
                                                />
                                            )
                                        }>
                                        Upload Photo
                                        <VisuallyHiddenInput
                                            type='file'
                                            accept='image/png, image/jpeg ,image/jpg'
                                            onChange={handleChange}
                                        />
                                    </Button>

                                    <>
                                        <Typography variant='h6'>
                                            {employeeDetail?.firstName} {employeeDetail?.lastName}
                                        </Typography>
                                        <Typography
                                            sx={{ my: 1 }}
                                            color='text.secondary'
                                            variant='body2'>
                                            {employeeDetail.designation}
                                        </Typography>
                                        <Typography color='text.secondary' variant='caption'>
                                            D.O.B :
                                            {moment(employeeDetail.dob).utc().format('DD-MM-YYYY')}
                                        </Typography>
                                    </>

                                    <Divider />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={8}>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChangeTab}
                                        aria-label='basic tabs example'>
                                        <Tab
                                            label='Personal Details'
                                            sx={{ textTransform: 'capitalize' }}
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label='Bank Details'
                                            sx={{ textTransform: 'capitalize' }}
                                            {...a11yProps(1)}
                                        />
                                    </Tabs>
                                </Box>
                                <Card elevation={0}>
                                    {employeeDetail ? (
                                        <>
                                            <CustomTabPanel value={value} index={0}>
                                                <Editprofile
                                                    profile={employeeDetail}
                                                    setEmployeeDetail={setEmployeeDetail}
                                                />
                                            </CustomTabPanel>

                                            <CustomTabPanel value={value} index={1}>
                                                <BankDetails
                                                    profile={employeeDetail}
                                                    setEmployeeDetail={setEmployeeDetail}
                                                />
                                            </CustomTabPanel>
                                        </>
                                    ) : (
                                        <Loading />
                                    )}
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Loading />
                )}
            </Box>
        </>
    );
};

export default Index;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
