import React from 'react';
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Payslipstable from '../components/Payslipstable';

const index = () => {
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Payslips</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={4}>
                <Card elevation={0} sx={{ position: 'relative' }}>
                    <CardContent>
                        <Box
                            sx={{
                                borderLeftWidth: '5px',
                                borderLeftColor: 'primary.main',
                                borderLeftStyle: 'solid',
                                height: '40px',
                                width: '5px',
                                position: 'absolute',

                                left: 1,
                            }}></Box>
                        <Typography variant='h6' sx={{ mb: 3 }}>
                            My Payslips Summary
                        </Typography>

                        <Payslipstable />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default index;
