import { Box, Container, Typography, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Image } from './Image';
import  HrLogo  from '../assets/ClikkleFavicons/hr-text.png';
import  noAccess  from '../assets/noAccess.svg';

const Error = props => {
    const { error, errorCode } = props;

    return (
        <Container maxWidth='lg'>
            <Box
                display='flex'
                justifyContent='center'
                alignItems={'center'}
                flexDirection={'column'}
                minHeight='calc(100vh - 125px)'>
                {/* <Stack direction='horizontal' m={2}> */}

                {/* <Image alt='clikkle img' src={noAccess} sx={{ height: '47px' }} /> */}
                
                    {/* <img alt='clikkle img' src='https://cdn.clikkle.com/images/clikkle/logo/2023/clikkle-text.png' sx={{ height: '150px' }} />{' '} */}
                    {/* <Typography variant='h3' fontWeight={500} mx={1} letterSpacing={'1.8px'}>
                        Catch
                    </Typography> */}
                {/* </Stack> */}
                {/* <Box ml={2} justifyContent={"center"}  alignItems={"center"} > */}
                <Image alt='clikkle img' src={noAccess} sx={{ height: '347px' }} />
                    {/* <Typography variant='h6' color='text.secondary' fontWeight={400} mb={3}>
                        <Typography
                            variant='h5'
                            fontWeight={500}
                            color='text.primary'
                            component='span'>
                            {errorCode}. {'   '}
                        </Typography>
                        That's an error.
                    </Typography> */}
                    <Typography variant='h6' sx={{ textAlign: "center"}} m={5}>
                        {error}
                    </Typography>
                    {/* <Typography variant='subtitle1' color='text.secondary'>
                        That's all we know
                    </Typography> */}
                {/* </Box> */}
            </Box>
        </Container>
    );
};

export default Error;
