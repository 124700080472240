import {
    Box,
    Button,
    Card,
    Chip,
    Grid,
    IconButton,
    Modal,
    Pagination,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import useModal from '../../hooks/useModal';
import NewProject from './NewProject';
import EditIcon from '@mui/icons-material/Edit';
import Loading from '../../components/Loading';
import useErrorHandler from '../../hooks/useErrorHandler';

const Project = () => {
    const [projects, setProjects] = useState(null);
    const { state: projectState, openModal: openProject, closeModal: closeProject } = useModal();
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(0);
    const errorHandler = useErrorHandler();

    const fetchProjects = useCallback(async () => {
        try {
            const response = await axios.get(
                `/employee/projects?sortBy=createdAt&direction=-1&page=${pageNo}`
            );
            setProjects(response.data.projects);

            setPageLimit(response.data?.pageDate?.totalPages);
        } catch (e) {
            errorHandler(e);
        }
    }, [errorHandler, pageNo]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    return (
        <Box>
            <Box sx={{ mt: 3, mb: 2 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Projects</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Button variant='contained' onClick={openProject}>
                                New project
                            </Button>
                        </Box>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0, ml: 2 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box my={2} sx={{ mb: '80px' }}>
                <Pagination
                    color='primary'
                    sx={{ float: 'right' }}
                    count={pageLimit}
                    page={pageNo}
                    onChange={(_, newPage) => setPageNo(newPage)}
                />
            </Box>

            {projects ? (
                projects?.map(project => (
                    <ProjectCard
                        title={project.title}
                        description={project.description}
                        statusInfo={project.status}
                        startDate={project.createdAt}
                        key={project._id}
                        projectId={project._id}
                        fetchProjects={fetchProjects}
                    />
                ))
            ) : (
                <Grid container spacing={2} my={1.5}>
                    {Array(10)
                        .fill(0)
                        .map((el, i) => (
                            <Grid item xs={12} key={i}>
                                <Skeleton
                                    variant='rectangular'
                                    animation='wave'
                                    height='150px'
                                    sx={{ borderRadius: '10px' }}
                                />
                            </Grid>
                        ))}
                </Grid>
            )}
            <Modal
                sx={{
                    overflowY: 'scroll',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                open={projectState}
                onClose={closeProject}>
                <>
                    {projects ? (
                        <NewProject closeModal={closeProject} refresh={fetchProjects} />
                    ) : (
                        <Loading />
                    )}
                </>
            </Modal>
        </Box>
    );
};

const ProjectCard = props => {
    const { title, description, statusInfo, startDate, projectId, fetchProjects } = props;

    const { state: projectState, openModal: openProject, closeModal: closeProject } = useModal();

    const [projectDetails, setProjectDetails] = useState(null);

    const fetchProjectsDetail = useCallback(async () => {
        try {
            const response = await axios.get(`/employee/projects/${projectId}`);

            setProjectDetails(response.data.project);
        } catch (e) {
            console.log(e);
        }
    }, [projectId]);

    const handleChangeEdit = () => {
        openProject();
        fetchProjectsDetail();
    };

    return (
        <>
            <Box my={3}>
                <Card
                    elevation={0}
                    sx={{
                        borderRadius: '20px',
                        p: 3,
                        position: 'relative',
                        // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    }}>
                    <Box
                        sx={{
                            borderLeftWidth: '5px',
                            borderLeftColor: 'primary.main',
                            borderLeftStyle: 'solid',
                            height: '40px',
                            width: '5px',
                            position: 'absolute',

                            left: 1,
                        }}></Box>
                    <Grid container rowSpacing={1}>
                        <Grid item xs>
                            <Typography variant='h6' fontWeight='bold'>
                                {title}{' '}
                                <span
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 300,
                                    }}>
                                    {new Date(startDate).toDateString()}
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item md={2} textAlign='right'>
                            <Chip label={statusInfo} variant='outlined' />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography variant='body2' mb={2}>
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box textAlign='right'>
                        <IconButton onClick={handleChangeEdit}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                </Card>
            </Box>
            <Modal
                sx={{
                    overflowY: 'scroll',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                open={projectState}
                onClose={closeProject}>
                {projectDetails ? (
                    <>
                        <NewProject
                            closeModal={closeProject}
                            refresh={fetchProjects}
                            title={projectDetails.title}
                            description={projectDetails.description}
                            statusInfo={projectDetails.status}
                            projectId={projectId}
                            startDate={startDate}
                        />
                    </>
                ) : (
                    <Loading />
                )}
            </Modal>
        </>
    );
};

export default Project;
