import { Box, Button, Card, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import Details from './Details';

const MemoCard = (props) => {
    const { title, content } = props;
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleExpand = () => {
        setAccordionOpen((show) => !show);
    };

    return (
        <Box my={2}>
            <Card
                elevation={0}
                sx={{
                    borderRadius: '20px',
                    p: 3,
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                }}
            >
                <Grid
                    container
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item xs={12} md>
                        <Typography variant='body1' fontWeight='bold'>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md='auto' mt={{ xs: 1, md: 0 }}>
                        <Button
                            sx={{
                                color: 'inherit',
                                textTransform: 'capitalize',
                                fontSize: '16px',
                                mr: 2,
                            }}
                            onClick={handleExpand}
                            variant='text'
                            endIcon={<ExpandMoreIcon />}
                        >
                            show details
                        </Button>
                    </Grid>
                </Grid>
                {accordionOpen && <Details content={content} title={title} />}
            </Card>
            {/* <Modal sx={{ overflowY: 'scroll' }} open={memoState} onClose={closeMemo}>
                <CreateMemo closeModal={closeMemo} />
            </Modal> */}
        </Box>
    );
};

export default MemoCard;
