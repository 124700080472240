import { Box, Tab, Tabs, Typography, TextField, IconButton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

// import { Image } from '../../components/Image';
// import { Form, Submit, useForm } from '../../hooks/useForm';
// import { Input } from '../../hooks/useForm/inputs';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DrawIcon from '@mui/icons-material/Draw';
import axios from 'axios';
import { getCookie } from '../../utils/cookies';
import useLoader from '../../hooks/useLoader';
import { escapeDanger } from '../../utils/function';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useMessage } from '../../layouts/Header';
import CompleteAgreement from './CompleteAgreement';

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index}>
            {value === index && (
                <Box p={1}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const Agreement = () => {
    const [agreementDetails, setAgreementDetils] = useState(null);
    const [isAllAgreementSign, setIsAllAgreementSign] = useState(false);
    const { pageloading, start, end } = useLoader({
        pageHeight: 'calc(100vh - 470px)',
        size: 46,
    });
    const errorHandler = useErrorHandler();
    // const { state, closeModal, openModal } = useModal();
    const [tabSelected, setTabSelected] = useState(0);
    const { showSuccess } = useMessage();
    const [sign, setSign] = useState({});

    // const messages = useMemo(
    //     () => ({
    //         0: (
    //             <>
    //                 You have successfully signed the Intellectual Property Agreement, and now you
    //                 can proceed to sign the Non-Disclosure Agreement by clicking the
    //                 {
    //                     <Button
    //                         variant='outlined'
    //                         sx={{ p: 0, mx: 1 }}
    //                         onClick={() => {
    //                             setTabSelected(1);
    //                             closeModal();
    //                         }}>
    //                         Next
    //                     </Button>
    //                 }
    //                 Button.
    //             </>
    //         ),
    //         1: (
    //             <>
    //                 You have successfully signed the Non-Disclosure Agreement, and now you can
    //                 proceed to sign the Employement Contract Agreement by clicking the{' '}
    //                 {
    //                     <Button
    //                         variant='outlined'
    //                         sx={{ p: 0, mx: 1 }}
    //                         onClick={() => {
    //                             setTabSelected(2);
    //                             closeModal();
    //                         }}>
    //                         Next
    //                     </Button>
    //                 }{' '}
    //                 Button.
    //             </>
    //         ),
    //         2: (
    //             <>
    //                 Congratulations on successfully signing all the agreements. Now, please allow
    //                 some time for our HR department to process the agreements and officially onboard
    //                 you as an employee at {<Link href='https://catch.com'> catch.com </Link>}.
    //             </>
    //         ),
    //     }),
    //     [closeModal]
    // );

    const fetchAgreement = useCallback(
        async function () {
            start();
            try {
                const response = await axios.get('/user/agreement', {
                    headers: {
                        Authorization: `Bearer ${getCookie('accessToken')}`,
                    },
                });
                const agreemnets = response.data.agreements;

                const obj = {};
                let isNotSign =  false ;
                agreemnets.forEach(agreement => {
                    obj[agreement.title] = '';
                    if(!agreement?.sign){
                        isNotSign = true
                    }      
                });
                setIsAllAgreementSign(!isNotSign);

                setSign(obj);

                setAgreementDetils(agreemnets);
            } catch (e) {
                errorHandler(e);
            } finally {
                end();
            }
        },
        [errorHandler, start, end]
    );

    const signAgrement = useCallback(
        async (sign, agreementId) => {
            try {
                const response = await axios.patch('/user/agreement/sign', { sign, agreementId });

                const { success, message } = response.data;
                if (success) {
                    showSuccess(message);
                    fetchAgreement();
                }
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, showSuccess, fetchAgreement]
    );

    const tabHandleChange = (event, newValue) => {
        setTabSelected(newValue);
    };

    useEffect(() => {
        fetchAgreement();
    }, [fetchAgreement]);

    return (
        <Box>

                {isAllAgreementSign ? 
                <CompleteAgreement /> : 
              <>          
            {agreementDetails ? (
                <Box sx={{ px: { lg: 5, xs: 0 } }}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            maxWidth: { xs: '100%' },
                        }}>
                        <Tabs
                            value={tabSelected}
                            onChange={tabHandleChange}
                            variant='scrollable'
                            scrollButtons
                            allowScrollButtonsMobile>
                            {agreementDetails.map(agreement => (
                                <Tab label={agreement.title} />
                            ))}
                        </Tabs>
                    </Box>

                    {agreementDetails ? (
                        agreementDetails.map((agreement, index) => (
                            <TabPanel value={tabSelected} index={index}>
                                <Box
                                    p={2}
                                    dangerouslySetInnerHTML={{
                                        __html: escapeDanger(agreement.content),
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: { md: 'flex', xs: 'block' },
                                        mt: { md: 5, xs: 0 },
                                    }}
                                    alignItems='center'>
                                    <Box flexBasis='50%'>
                                        <Box
                                            display='flex'
                                            alignItems='center'
                                            mt={1}
                                            flexBasis='30%'>
                                            Signature{' '}
                                            <TextField
                                                size='small'
                                                sx={{
                                                    '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                                        fontFamily: '"Playwrite CU", cursive',
                                                        fontSize: 25,
                                                        fontWeight:300
                                                    },
                                                    width: '50%',

                                                    pr: 1,
                                                    ml: 2,
                                                    '& input::placeholder': {
                                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                                        fontSize: 20,
                                                    },
                                                }}
                                                name={agreement.title}
                                                value={sign[agreement.title] || agreement.sign}
                                                placeholder='Your Signature Here'
                                                onChange={e =>
                                                    setSign(prev => ({
                                                        ...prev,
                                                        [e.target.name]: e.target.value,
                                                    }))
                                                }
                                                disabled={agreement.sign}
                                                fullWidth
                                            />
                                            {agreement?.sign ? null : (
                                                <IconButton
                                                    disabled={!sign[agreement.title]}
                                                    onClick={() =>
                                                        signAgrement(
                                                            sign[agreement.title],
                                                            agreement.agreementId
                                                        )
                                                    }>
                                                    <DrawIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                        Date : {new Date().toLocaleDateString()}
                                        <br />
                                    </Box>

                                    {/* <Box flexBasis='50%' sx={{ mt: { md: 0, xs: 5 } }}>
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                            }}>
                                            EMPLOYER
                                        </span>{' '}
                                        <br />
                                        <br />
                                        Name : {intellectualProperty.employer.sign} <br />
                                        <Box
                                            display='flex'
                                            alignItems='center'
                                            mt={1}
                                            flexBasis='30%'>
                                            Signature{' '}
                                            <TextField
                                                size='small'
                                                sx={{
                                                    '& .MuiInputBase-root.MuiOutlinedInput-root': {
                                                        fontFamily:
                                                            "'Mrs Saint Delafield', cursive",
                                                        fontSize: 30,
                                                    },
                                                    width: '50%',

                                                    pr: 1,
                                                    ml: 2,
                                                    '& input::placeholder': {
                                                        fontFamily: 'Arial, Helvetica, sans-serif',
                                                        fontSize: 20,
                                                    },
                                                }}
                                                value={intellectualProperty.employer.sign}
                                                disabled={intellectualProperty.employer.sign}
                                                fullWidth
                                            />
                                        </Box>
                                        Date :
                                        {intellectualProperty.employer.time
                                            ? new Date(
                                                  intellectualProperty.employer.time
                                              ).toLocaleDateString()
                                            : date}
                                        <br />
                                    </Box> */}
                                </Box>
                            </TabPanel>
                        ))
                    ) : (
                        <Typography variant='subtitle1'>
                            "I appreciate your time and effort throughout the interview process. At
                            this time, we haven't found a job match, but please don't lose hope.
                            Your skills and potential are valuable, and the right opportunity is out
                            there. Keep your chin up and continue your search with determination."
                        </Typography>
                    )}
                </Box>
            ) : (
                pageloading
            )}
            </>}

            {/* <Modal
                open={state}
                onClose={closeModal}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Card
                    sx={{
                        maxWidth: 530,
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 2,
                        mx: 1,
                        textAlign: 'center',
                        outline: 'transparent',
                    }}>
                    <Typography sx={{ my: 2 }}>
                        🎉 You've taken the third step towards joining our team! 🎉
                    </Typography>
                    <Typography variant='subtitle1' mb={1}>
                        {messages[tabSelected]}
                    </Typography>
                </Card>
            </Modal> */}
        </Box>
    );
};
export default Agreement;
