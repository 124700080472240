import React, { useMemo, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Input } from '../../hooks/useForm/inputs';
import { Form, useForm, Submit } from '../../hooks/useForm';
import { useMessage } from '../../layouts/Header';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import moment from 'moment/moment';

const Editprofile = ({ profile, setEmployeeDetail }) => {
    const {
        email,
        firstName,
        lastName,
        dob,
        dateOfJoining,
        designation,
        department,
        salary,
        gender,
        timezone,
        jobType,
        fatherName,
        emergencyContact,
        presentAddress,
        permanentAddress,
        maritalStatus,
        bloodGroup,
    } = profile;

    const joinDate = moment(dateOfJoining).utc().format('YYYY-MM-DD');
    const Dob = moment(dob).utc().format('YYYY-MM-DD');

    const handlers = useForm(
        useMemo(
            () => ({
                firstName: { value: firstName },
                lastName: { value: lastName },
                fatherName: { value: fatherName },
                dob: { value: Dob },
                emergencyContact: { value: emergencyContact },
                presentAddress: { value: presentAddress },
                permanentAddress: { value: permanentAddress },
            }),
            [
                firstName,
                lastName,
                Dob,
                permanentAddress,
                presentAddress,
                emergencyContact,
                fatherName,
            ]
        ),
        { Input: TextField }
    );

    const [employ, setEmploy] = useState({
        gender: gender,
        timezone: timezone,
        jobType: jobType,
        maritalStatus: maritalStatus,
        bloodGroup: bloodGroup,
    });

    const [phone, setPhone] = useState({
        countryCode: profile.phone.countryCode,
        phone: profile.phone.phone,
    });

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setEmploy({ ...employ, [name]: value });
    };

    const handleChangePhone = e => {
        const name = e.target.name;
        const value = e.target.value;
        setPhone({ ...phone, [name]: value });
    };

    const { showSuccess, showError } = useMessage();
    const submit = res => {
        const { success } = res.data;
        if (!success) return showError('You can not Update This Field');

        // fetchprofile();
        setEmployeeDetail({ ...profile, ...handlers.values });
        showSuccess('Profile Edit successfully');
    };

    return (
        <>
            <Form
                handlers={handlers}
                onSubmit={submit}
                action={'/employee/profile'}
                method='patch'
                final={values => ({
                    ...values,
                    ...employ,
                    phone,
                })}
                retainOnSubmit
                onError={console.log}>
                <Grid container spacing={1} display='flex' alignItems='center'>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>First Name</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input name='firstName' fullWidth size='small' required />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Last Name</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            size='small'
                            placeholder='Last Name'
                            name='lastName'
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Father Name</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            size='small'
                            placeholder='Father Name'
                            name='fatherName'
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Country Code</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                size='small'
                                placeholder='Country Code'
                                name='countryCode'
                                onChange={handleChangePhone}
                                value={phone?.countryCode}
                                type='number'
                                required
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Contact Number</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                onChange={handleChangePhone}
                                value={phone.phone}
                                size='small'
                                placeholder='Phone Number'
                                name='phone'
                                type='number'
                                required
                                fullWidth
                            />
                        </Box>
                    </Grid>

                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Date Of Birth (MM:DD:YYYY)</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input type='date' name='dob' required size='small' fullWidth />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Gender</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <FormControl sx={{ display: 'flex' }} size='small'>
                            <RadioGroup
                                row
                                aria-labelledby='demo-radio-buttons-group-label'
                                defaultValue='female'
                                name='gender'
                                onChange={handleChange}
                                value={employ.gender}>
                                <FormControlLabel
                                    value='female'
                                    control={<Radio />}
                                    label='Female'
                                />
                                <FormControlLabel value='male' control={<Radio />} label='Male' />
                                <FormControlLabel value='other' control={<Radio />} label='Other' />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Marital Status</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box>
                            <FormControl fullWidth size='small'>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={employ.maritalStatus}
                                    onChange={handleChange}
                                    name='maritalStatus'>
                                    <MenuItem value='Married'>Married</MenuItem>
                                    <MenuItem value='Widowed'>Widowed</MenuItem>
                                    <MenuItem value='Separated'>Separated</MenuItem>
                                    <MenuItem value='Divorced'>Divorced</MenuItem>
                                    <MenuItem value='Single'>Single</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Blood Group</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box>
                            <FormControl fullWidth size='small'>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={employ.bloodGroup}
                                    onChange={handleChange}
                                    name='bloodGroup'>
                                    <MenuItem value='A+'>A+</MenuItem>
                                    <MenuItem value='B+'>B+</MenuItem>
                                    <MenuItem value='AB+'>AB+</MenuItem>
                                    <MenuItem value='O+'>O+</MenuItem>
                                    <MenuItem value='A-'>A-</MenuItem>
                                    <MenuItem value='B-'>B-</MenuItem>
                                    <MenuItem value='AB-'>AB-</MenuItem>
                                    <MenuItem value='O-'>O-</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Email</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <TextField
                                disabled
                                value={email}
                                placeholder='email'
                                fullWidth
                                size='small'
                            />
                        </Box>
                    </Grid>

                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Department Id</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <TextField
                                disabled
                                value={department}
                                placeholder='departmentId'
                                fullWidth
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Designation</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <TextField
                                disabled
                                value={designation}
                                size='small'
                                required
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Date Of Joining (MM:DD:YYYY)</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            disabled
                            value={joinDate}
                            size='small'
                            type='date'
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Emergency Contact</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input size='small' name='emergencyContact' fullWidth required />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Present Address</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input size='small' name='presentAddress' fullWidth required />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Permanent Address</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <Input size='small' name='permanentAddress' fullWidth required />
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>shiftStart</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <TextField
                                disabled
                                name='shiftStart'
                                type='time'
                                value={`${profile.shiftStart.hour
                                    .toString()
                                    .padStart(2, '0')}:${profile.shiftStart.minute
                                    .toString()
                                    .padStart(2, '0')}`}
                                placeholder='Shift Start Time'
                                fullWidth
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>shiftEnd</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <TextField
                                disabled
                                name='shiftend'
                                type='time'
                                value={`${profile.shiftEnd.hour
                                    .toString()
                                    .padStart(2, '0')}:${profile.shiftEnd.minute
                                    .toString()
                                    .padStart(2, '0')}`}
                                placeholder='Shift End Time'
                                fullWidth
                                size='small'
                            />
                        </Box>
                    </Grid>

                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Time Zone</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <FormControl fullWidth size='small'>
                            <Select
                                disabled
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={employ.timezone}
                                onChange={handleChange}
                                name='timezone'>
                                {Intl.supportedValuesOf('timeZone')?.map(timezone => (
                                    <MenuItem value={timezone}>{timezone}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xl={3} xs={12} mt={2}>
                        <Typography variant='caption'>Type</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12} mb={2}>
                        <Box>
                            <FormControl fullWidth size='small'>
                                <Select
                                    disabled
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={employ.jobType}
                                    onChange={handleChange}
                                    name='jobType'
                                    defaultValue='Full Time'>
                                    <MenuItem value={'Full Time'}>Full-Time</MenuItem>
                                    <MenuItem value={'Part Time'}>Part-Time</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Salary</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Box display='flex'>
                            <Input
                                type='number'
                                disabled
                                value={salary.amount}
                                placeholder='$Salary'
                                size='small'
                                fullWidth
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={2} textAlign='right'>
                    <Button
                        variant='outlined'
                        sx={{
                            mx: 2,
                        }}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}
                                variant='contained'>
                                Update
                            </Button>
                        )}
                    </Submit>
                </Box>
            </Form>
        </>
    );
};

export default Editprofile;
