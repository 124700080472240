import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Images } from '../../components/Image';

const CompleteOfferLetter = () => {
    return (
        <Grid
            container
            spacing={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
           
            // style={{ height: '40vh' }}
            my={3}>
            <Grid
                item
                lg={3}
                xs={12}
                sx={{ textAlign: { lg: 'right', xs: 'center' } , display : { sm: 'block', xs: 'none' } }}
                justifyContent='center'>
                <Images src='offerLetter.svg' style={{ height: '250px', margin:0 }} />
            </Grid>
            <Grid
                item
                lg={4}
                xs={12}
                sx={{ textAlign: { lg: 'right', xs: 'center' } , display : { sm: 'none', xs: 'block' } ,  mb :3 }}
                justifyContent='center'>
                <Images src='offerLetter.svg' style={{ height: '165px',  margin:"auto" }} />
            </Grid>
            
            <Grid item lg={6} xs={12}>
                {' '}
                <Typography sx={{ maxWidth: '700px', ml: 4 , fontSize: { sm: '1.25rem', xs: '1rem' }  }} variant='h6'>
                We are delighted to confirm that we have received your signed job offer letter. Congratulations, and thank you for choosing to join us! Please be patient as we prepare for your onboarding process.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CompleteOfferLetter;
