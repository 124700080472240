import {
    Box,
    Card,
    CardContent,
    Divider,
    Button,
    Grid,
    IconButton,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardCard from '../../components/DashboardCard';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import DashboardBarChart from '../../components/DashboardBarChart';
import Calender from '../../components/Calender';
import Holiday from '../../components/Holiday';
// import Search from '../components/Search';
import LeaveOverview from '../../components/LeaveOverview';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import AddIcon from '@mui/icons-material/Add';
// import RecentJobTable from '../components/RecentJobTable';
// import BirthDayTable from '../components/BirthDayTable';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Noticecard from './NoticeCard';
const Index = () => {
    const [time, setTime] = useState();
    const [Dates, setDate] = useState();
    const [EmployeeOverview, setEmployeeOverview] = useState({});

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);
        const Date1 = setInterval(() => {
            setDate(new Date().toLocaleDateString());
        }, 1000);

        return () => {
            clearInterval(timer);
            clearInterval(Date1);
        };
    }, []);

    const fetchOverview = useCallback(async function () {
        try {
            const response = await axios.get(`/employee/dashboard`);
            setEmployeeOverview(response.data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        fetchOverview();
    }, [fetchOverview]);
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Employee Dashboard</Typography>
                    </Grid>
                    <Grid item sx={{ display: { xs: 'flex' }, mx: 'auto' }} alignItems='center'>
                        <Box sx={{ mr: 2, display: { lg: 'block', xs: 'none' } }}>
                            <Button variant='outlined'>{Dates}</Button>
                        </Box>
                        <Button variant='outlined' sx={{ display: { lg: 'block', xs: 'none' } }}>
                            {time}
                        </Button>
                        <Box sx={{ mx: 2 }}>
                            <Button variant='contained'>Clock In</Button>
                        </Box>

                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    {/* <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
            {/* cards */}
            <Box mt={6}>
                <Grid container spacing={4}>
                    {EmployeeOverview ? (
                        <>
                            <DashboardCard
                                Title=' Completed Projects'
                                Number={EmployeeOverview?.completedProjects || 0}
                                Color='#0DCD94'
                                Icon=<DescriptionOutlinedIcon />
                            />
                            <DashboardCard
                                Title='Total Attendance'
                                Number={EmployeeOverview?.totalAttendance || 0}
                                Color='#3366FF'
                                Icon=<WidgetsOutlinedIcon />
                            />
                            <DashboardCard
                                Title='Absent'
                                Number={EmployeeOverview?.absents || 0}
                                Color='#FE7F00'
                                Icon=<HomeRepairServiceOutlinedIcon />
                            />
                            <DashboardCard
                                Title='Awards'
                                Number={EmployeeOverview?.awards || 0}
                                Color='#F7284A'
                                Icon=<MilitaryTechOutlinedIcon />
                            />
                        </>
                    ) : (
                        <>
                            {Array(4)
                                .fill(0)
                                .map((el, i) => (
                                    <Grid item lg={6} xl={3} xs={12} key={i}>
                                        <Skeleton
                                            variant='rectangular'
                                            animation='wave'
                                            height='112px'
                                            sx={{
                                                borderRadius: '4px',
                                            }}
                                        />
                                    </Grid>
                                ))}
                        </>
                    )}
                </Grid>
            </Box>
            {/* charts */}
            <Box my={5}>
                <Grid container spacing={4}>
                    <Grid item lg={8} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Box
                                    display='flex'
                                    alignItems='cemter'
                                    justifyContent='space-between'>
                                    <Box>
                                        <Typography variant='h6' sx={{ mb: 2 }}>
                                            Projects And Attendance Chart
                                        </Typography>
                                    </Box>
                                </Box>
                                <DashboardBarChart
                                    projects={EmployeeOverview.projects}
                                    attendance={EmployeeOverview.attendance}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* recent activity */}
                    <Grid item lg={4} xs={12}>
                        <Card elevation={0} sx={{ position: 'relative', height: '100%' }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Typography variant='h6' sx={{ mb: 3 }}>
                                    Notice Board
                                </Typography>
                                {EmployeeOverview.notices?.map((notice, i) => (
                                    <React.Fragment key={notice._id}>
                                        <Noticecard
                                            title={notice.title}
                                            caption={notice.caption}
                                            date={notice.createdAt}
                                            Background='custom.buttonColor.light.blue'
                                            Textcolor='custom.buttonColor.dark.blue'
                                        />
                                        {i === 8 ? null : <Divider sx={{ my: 1 }} />}
                                    </React.Fragment>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            {/* Holiday information */}
            <Box mb={5}>
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={12}>
                        <Calender />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Card sx={{ height: '100%', position: 'relative' }} elevation={0}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Typography variant='h6' sx={{ mb: 3 }}>
                                    Up Coming Holidays
                                </Typography>
                                {EmployeeOverview.holidays
                                    ? EmployeeOverview.holidays.map(holiday => (
                                          <React.Fragment key={holiday._id}>
                                              <Holiday
                                                  Background='custom.buttonColor.light.lightGreen'
                                                  Textcolor='custom.buttonColor.dark.lightGreen'
                                                  holiday={holiday}
                                              />
                                              <Divider sx={{ my: 1 }} />
                                          </React.Fragment>
                                      ))
                                    : null}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <LeaveOverview leaves={EmployeeOverview.leaves} />
                    </Grid>
                </Grid>
            </Box>
            {/* Job Application
             <Box my={5}>
                <Grid container spacing={2}>
                    <Grid item lg={7} xs={12}>
                        <Card>
                            <CardContent>
                                <Box
                                    mb={5}
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='space-between'
                                >
                                    <Typography variant='h6'>
                                        Recent Job Application
                                    </Typography>

                                    <IconButton>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    sx={{
                                        maxWidth: '300px',
                                        ml: 'auto',

                                        my: 4,
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        sx={{ mx: 2 }}
                                        variant='contained'
                                        endIcon={<AddIcon />}
                                    >
                                        New Task
                                    </Button>
                                    <Search />
                                </Box>
                                <RecentJobTable />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Birthday */}
            {/* <Grid item lg={5} xs={12}>
                        <Card>
                            <CardContent>
                                <Box
                                    mb={5}
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='space-between'
                                >
                                    <Typography variant='h6'>
                                        Up Coming Birthdays
                                    </Typography>

                                    <Button variant='contained'>
                                        View All
                                    </Button>
                                </Box>
                                <BirthDayTable />
                                <Divider sx={{ my: 2 }} />
                                <BirthDayTable />
                                <Divider sx={{ my: 2 }} />
                                <BirthDayTable />
                                <Divider sx={{ my: 2 }} />
                                <BirthDayTable />
                                <Divider sx={{ my: 2 }} />
                                <BirthDayTable />
                                <Divider sx={{ my: 2 }} />
                                <BirthDayTable />
                                <Divider sx={{ my: 2 }} />
                                <BirthDayTable />
                            </CardContent>
                        </Card>
                    </Grid> */}
            {/* </Grid>
            </Box>  */}
        </>
    );
};

export default Index;
