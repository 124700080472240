import React, { useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import VideoChatOutlinedIcon from "@mui/icons-material/VideoChatOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { NavLink } from "react-router-dom";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";

import AtsIcon from "../services/icons/menu/attndance.svg";
import ActiveAtsIcon from "../services/icons/active-menu/attndance.svg";

import ProjectIcon from "../services/icons/menu/leave.svg";
import ActiveProjectIcon from "../services/icons/active-menu/leave.svg";

import ChatIcon from "../services/icons/menu/chat.svg";
import ActiveChatIcon from "../services/icons/active-menu/chat.svg";

import HomeIcon from "../services/icons/menu/ic_outline-home.svg";
import ActiveHomeIcon from "../services/icons/active-menu/ic_outline-home.svg";

import AppsIcon from "../services/icons/menu/apps.svg";
import ActiveAppsIcon from "../services/icons/active-menu/apps.svg";
import { Box, ListItemIcon, SvgIcon, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import GroupsIcon from "@mui/icons-material/Groups";

const Attendance = ({ active }) => (
  <img
    src={active ? ActiveAtsIcon : AtsIcon}
    alt="Attendance Icon"
    style={{ width: "20px", height: "20px" }}
  />
);
const Project = ({ active }) => (
  <img
    src={active ? ActiveProjectIcon : ProjectIcon}
    alt="Interview Icon"
    style={{ width: "20px", height: "20px" }}
  />
);
const Chat = ({ active }) => (
  <img
    src={active ? ActiveChatIcon : ChatIcon}
    alt="Interview Icon"
    style={{ width: "20px", height: "20px" }}
  />
);

const Home = ({ active }) => (
  <img
    src={active ? ActiveHomeIcon : HomeIcon}
    alt="Interview Icon"
    style={{ width: "20px", height: "20px" }}
  />
);
const Apps = ({ active }) => (
  <img
    src={active ? ActiveAppsIcon : AppsIcon}
    alt="Interview Icon"
    style={{ width: "20px", height: "20px" }}
  />
);
const Footer = () => {
  const [activeIcon, setActiveIcon] = useState(null);

  const handleIconClick = (iconName) => {
    setActiveIcon(iconName === activeIcon ? null : iconName);
  };

  const styles = {
    navLinkContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
    },
    iconContainer: {
      padding: "4px 8px", // equivalent to px-2 py-1
      width: "90%",
      display: "flex",
      justifyContent: "center",
    },
    activeIconContainer: {
      borderRadius: "1.5rem",
      backgroundColor: "rgba(33, 150, 243, 0.15)", // equivalent to bg-blue-500/[.15]

    },
    activeText: {
      color: "#3b82f6", // equivalent to text-blue-500
    },
    inActiveText :{
      color: "#A5A5A5"
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        height: "59px",
        position: "fixed",
        bottom: 0,
        color: "gray.500",
        width: "100%",
        fontSize: "10px",
        display: "flex",
        paddingRight : 1.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "@media (min-width: 768px)": {
          display: "none",
        },
      }}
    >
      <NavLink to="/" style={{ textDecoration: "none" }}>
        {({ isActive }) => (
          <div
            style={styles.navLinkContainer}
            onClick={() => handleIconClick("home")}
          >
            <div
              style={{
                ...styles.iconContainer,
                ...(isActive ? styles.activeIconContainer : {}),
              }}
            >
              <Home active={isActive} />
            </div>
            <div style={isActive ? styles.activeText : styles.inActiveText}>Home</div>
          </div>
        )}
      </NavLink>

      <NavLink to="/attendance" style={{ textDecoration: "none" }}>
        {({ isActive }) => (
          <div
            style={styles.navLinkContainer}
            onClick={() => handleIconClick("attendance")}
          >
            <div
              style={{
                ...styles.iconContainer,
                ...(isActive ? styles.activeIconContainer : {}),
              }}
            >
              <Attendance active={isActive} />
            </div>
            <div style={isActive ? styles.activeText :  styles.inActiveText}>Attendance</div>
          </div>
        )}
      </NavLink>

      <NavLink to="https://apps.clikkle.com/" style={{ textDecoration: "none" }} >
        {({ isActive }) => (
          <div
            style={styles.navLinkContainer}
            onClick={() => handleIconClick("app")}
          >
            <div
              style={{
                ...styles.iconContainer,
                ...(isActive ? styles.activeIconContainer : {}),
              }}
            >
              <Apps active={isActive} />
            </div>
          </div>
        )}
      </NavLink>

      <NavLink to="/my-leaves" style={{ textDecoration: "none" }}>
        {({ isActive }) => (
          <div
            style={styles.navLinkContainer}
            onClick={() => handleIconClick("leaves")}
          >
            <div
              style={{
                ...styles.iconContainer,
                ...(isActive ? styles.activeIconContainer : {}),
              }}
            >
              <Project active={isActive} />
            </div>
            <div style={isActive ? styles.activeText :  styles.inActiveText}>Leaves</div>
          </div>
        )}
      </NavLink>

      <NavLink to="/chat" style={{ textDecoration: "none" }}>
        {({ isActive }) => (
          <div
            style={styles.navLinkContainer}
            onClick={() => handleIconClick("chat")}
          >
            <div
              style={{
                ...styles.iconContainer,
                ...(isActive ? styles.activeIconContainer : {}),
              }}
            >
              <Chat active={isActive} />
            </div>
            <div style={isActive ? styles.activeText :  styles.inActiveText}>Chat</div>
          </div>
        )}
      </NavLink>
    </Box>
  );
};

export default Footer;
