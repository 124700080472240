import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs';
import { useMessage } from '../../layouts/Header';

const BankDetails = ({ profile, setEmployeeDetail }) => {
    const handlers = useForm(
        useMemo(
            () => ({
                accountHolder: {
                    value: profile.bank?.accountHolder ? profile.bank.accountHolder : '',
                },
                accountNumber: {
                    value: profile.bank?.accountNumber ? profile.bank?.accountNumber : '',
                },
                branch: {
                    value: profile.bank?.branch ? profile.bank.branch : '',
                },
                bankName: {
                    value: profile.bank?.bankName ? profile.bank.bankName : '',
                },
                ifsc: { value: profile.bank?.ifsc ? profile.bank.ifsc : '' },
                pan: { value: profile.bank?.pan ? profile.bank.pan : '' },
                city: { value: profile.bank?.city ? profile.bank.city : '' },
                state: { value: profile.bank?.state ? profile.bank.state : '' },
                country: {
                    value: profile.bank?.country ? profile.bank.country : '',
                },
            }),
            [
                profile.bank?.country,
                profile.bank?.state,
                profile.bank?.city,
                profile.bank?.pan,
                profile.bank?.ifsc,
                profile.bank?.bankName,
                profile.bank?.branch,
                profile.bank?.accountNumber,
                profile.bank?.accountHolder,
            ]
        ),
        { Input: TextField }
    );
    const { showSuccess, showError } = useMessage();
    const submit = res => {
        const { success } = res.data;
        if (!success) return showError('Bank Details not update');
        setEmployeeDetail({ ...profile.bank, ...handlers.values });
        showSuccess('Bank Details Update successfully');
    };
    return (
        <>
            <Form
                handlers={handlers}
                onSubmit={submit}
                action='employee/profile/bank'
                method='patch'
                onError={console.log}>
                <Grid container spacing={1} display='flex' alignItems='center'>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Account Holder</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Account Holder'
                            name='accountHolder'
                            size='small'
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Account Number</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Account Number'
                            name='accountNumber'
                            size='small'
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Bank Name</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Bank Name'
                            name='branch'
                            size='small'
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Branch Location</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Branch Location'
                            name='bankName'
                            size='small'
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Bank Code (IFSC) </Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Bank Code (IFSC) '
                            name='ifsc'
                            size='small'
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Tax Payer ID (PAN) </Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Tax Payer ID (PAN) '
                            name='pan'
                            size='small'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>City</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input placeholder='City' name='city' size='small' required fullWidth />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>State</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input placeholder='State' name='state' size='small' required fullWidth />
                    </Grid>
                    <Grid item xl={3} xs={12}>
                        <Typography variant='caption'>Country</Typography>
                    </Grid>
                    <Grid item xl={9} xs={12}>
                        <Input
                            placeholder='Country'
                            name='country'
                            size='small'
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box mt={2} textAlign='right'>
                    <Button
                        variant='outlined'
                        sx={{
                            mx: 2,
                        }}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}
                                variant='contained'>
                                Update
                            </Button>
                        )}
                    </Submit>
                </Box>
            </Form>
        </>
    );
};

export default BankDetails;
