import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Card, IconButton, Stack, Typography, Modal, Divider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import Close from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { isEmpty } from '../utils/function';
import useModal from '../hooks/useModal';
import { useReactToPrint } from 'react-to-print';

const columns = [
    { id: 'S. No.', label: 'S. No.', minWidth: 80 },
    { id: 'Employee', label: 'Employee', minWidth: 185 },
    { id: 'From', label: 'From', minWidth: 100 },
    { id: 'To', label: 'To', minWidth: 113 },
    { id: 'Salary', label: '($) Salary', minWidth: 60 },

    {
        id: 'Status',
        label: 'Status',
        minWidth: 45,
    },
    {
        id: 'Action',
        label: 'Action',
        minWidth: 50,
    },
];

const Payslipstable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [pageNo, setPageNo] = React.useState(1);
    // const [pageLimit, setPageLimit] = React.useState(0);
    const [selectedPayroll, setSelectedPayroll] = React.useState({});
    const { state, openModal, closeModal } = useModal();
    const [payroll, setPayroll] = React.useState(null);
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const fetchEmployeeSalary = React.useCallback(
        async function () {
            try {
                const response = await axios.get(`/employee/payslip?&sortBy=order&direction=-1`);
                const body = response.data;
                const { payslips } = body;
                setPayroll(payslips);
                // setPageLimit(response.data.pageData.totalPages);
            } catch (e) {
                console.log(e);
            }
        },
        [setPayroll]
    );

    const totalAllowance = React.useMemo(
        () =>
            isEmpty(selectedPayroll)
                ? 0
                : [
                      selectedPayroll.hraAllowance,
                      selectedPayroll.conveyance,
                      selectedPayroll.medicalAllowance,
                      selectedPayroll.bonusAllowance,
                  ].reduce((total, el) => {
                      return total + parseInt(el);
                  }, 0),
        [selectedPayroll]
    );

    const totalDeduction = React.useMemo(
        () =>
            isEmpty(selectedPayroll)
                ? 0
                : [
                      selectedPayroll.tds,
                      selectedPayroll.pf,
                      selectedPayroll.professionalTax,
                      selectedPayroll.loanAndOthers,
                  ].reduce((total, el) => {
                      return total + parseInt(el);
                  }, 0),
        [selectedPayroll]
    );

    console.log(selectedPayroll, state);

    React.useEffect(() => {
        fetchEmployeeSalary();
    }, [fetchEmployeeSalary]);

    return (
        <>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {payroll ? (
                        payroll.map((payroll, i) => (
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}>
                                    <TableCell component='th' scope='row'>
                                        {i + 1}
                                    </TableCell>

                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>
                                            {payroll.employee?.firstName +
                                                ' ' +
                                                payroll.employee?.lastName}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>
                                            {new Date(payroll.from).toDateString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>
                                            {new Date(payroll.to).toDateString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>{payroll.salary}</Typography>
                                    </TableCell>

                                    <TableCell component='th' scope='row'>
                                        <Typography variant='caption'>{payroll.status}</Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedPayroll(payroll);
                                                openModal();
                                            }}
                                            sx={{
                                                borderRadius: '12px',
                                                backgroundColor:
                                                    'custom.buttonColor.light.lightGreen',
                                                color: 'custom.buttonColor.dark.lightGreen',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'custom.buttonColor.light.lightGreen',
                                                },
                                            }}>
                                            <VisibilityIcon fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))
                    ) : (
                        <Typography>No data</Typography>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={payroll ? payroll?.length : '0'}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Modal
                open={state}
                onClose={closeModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    sx={{ p: 2.5, width: '100%', maxWidth: '840px' }}
                    elevation={0}
                    ref={componentRef}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6'>Payroll</Typography>
                        <div>
                            <IconButton
                                onClick={() => {
                                    handlePrint();
                                }}
                                sx={{
                                    mx: 1,
                                    borderRadius: '12px',
                                    backgroundColor: 'custom.buttonColor.light.yellow',
                                    color: 'custom.buttonColor.dark.yellow',
                                    '&:hover': {
                                        backgroundColor: 'custom.buttonColor.light.yellow',
                                    },
                                }}>
                                <PrintIcon fontSize='small' />
                            </IconButton>
                            <IconButton onClick={closeModal}>
                                <Close />
                            </IconButton>
                        </div>
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Employee
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.employee?.firstName +
                                ' ' +
                                selectedPayroll.employee?.lastName}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            From
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {new Date(selectedPayroll.from).toLocaleDateString()}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            To
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {new Date(selectedPayroll.to).toLocaleDateString()}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Status
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.status}
                        </Typography>
                    </Stack>
                    <Typography variant='h6' fontWeight={500} gutterBottom mt={2}>
                        Allowances
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            HRA allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.hraAllowance}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Conveyance
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.conveyance}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Medical allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.medicalAllowance}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Bonus allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.bonusAllowance}
                        </Typography>
                    </Stack>
                    <Typography variant='h6' fontWeight={500} gutterBottom mt={2}>
                        Deductions
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            PF
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.pf}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Professional Tax
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.professionalTax}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            TDS
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {selectedPayroll.loanAndOthers}
                        </Typography>
                    </Stack>
                    <Typography variant='h6' fontWeight={500} gutterBottom mt={2}>
                        Gross salary
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Total allowances
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {totalAllowance}
                        </Typography>
                    </Stack>

                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Total deductions
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {totalDeduction}
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle02' gutterBottom>
                            Net salary
                        </Typography>
                        <Typography variant='body1' gutterBottom color='text.secondary'>
                            {parseInt(selectedPayroll.salary) + totalAllowance - totalDeduction}
                        </Typography>
                    </Stack>
                </Card>
            </Modal>
        </>
    );
};

export default Payslipstable;
