import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useState } from 'react';
import { handleAxiosError } from '../../utils/function';
import { useMessage } from '../../layouts/Header';
import axios from 'axios';
import { useEffect } from 'react';
import NoticeCard from './NoticeCard';

const Memo = () => {
    const [notices, setNotices] = useState(null);
    const { showError } = useMessage();

    const fetchNotice = useCallback(async () => {
        try {
            const response = await axios.get('/employee/notice');

            setNotices(response.data.notices);
        } catch (e) {
            handleAxiosError(e, showError);
        }
    }, [showError]);

    useEffect(() => {
        fetchNotice();
    }, [fetchNotice]);

    return (
        <Box>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Notice Board</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {notices?.map(notice => (
                <NoticeCard
                    title={notice.title}
                    content={notice.content}
                    departmentIds={notice.departmentIds}
                    key={notice._id}
                />
            ))}
        </Box>
    );
};

export default Memo;
