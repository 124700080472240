import React from 'react';
import AuthenticationProvider from '../hooks/Authenticate';
import Navbar from '../layouts/Navbar';

const AuthorizeRouter = ({ children }) => {
    return (
        <AuthenticationProvider>
            <Navbar>{children}</Navbar>
        </AuthenticationProvider>
    );
};

export default AuthorizeRouter;
