import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const year = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Holiday = ({ Textcolor, Background, holiday }) => {
    const date = new Date(holiday?.date);
    const day = weekday[date.getDay()];
    const month = year[date.getMonth()];
    const d = new Date();
    const today = d.toISOString().split('T')[0];
    const holidayDate = date?.toISOString().split('T')[0];
    const timeDiff = date?.getTime() - d.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return (
        <>
            <Grid container spacing={2} display='flex' alignItems='center'>
                <Grid item>
                    <IconButton
                        sx={{
                            px: 1.2,
                            py: 0.5,
                            backgroundColor: Background,
                            borderRadius: '8px',
                            color: Textcolor,
                            '&:hover': {
                                backgroundColor: Background,
                            },
                        }}>
                        <Box display='flex' flexDirection='column'>
                            <Typography textAlign='center' fontWeight='bold' variant='caption'>
                                {date.getDate()}
                            </Typography>
                            <Typography
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'>
                                {month}
                            </Typography>
                        </Box>
                    </IconButton>
                </Grid>
                <Grid item xs>
                    <Typography variant='body2'>{holiday.title}</Typography>
                    <Typography variant='caption' fontWeight='bold'>
                        {day}
                    </Typography>
                </Grid>
                <Grid item xl={4} textAlign='center'>
                    <Typography variant='body2' color='text.secondary'>
                        {today === holidayDate ? 'Today' : `${daysLeft} days to left`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Holiday;
