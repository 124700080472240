import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Card,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Form, Submit } from './../hooks/useForm';
import { Input } from './../hooks/useForm/inputs';
import { useMessage } from '../layouts/Header';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { handleAxiosError } from '../utils/function';

const Index = ({ handleClose, fetchLeaves }) => {
    const [date, setDate] = useState({ from: null, to: null });
    const [leaveTypes, setLeaveType] = useState(null);
    const [leaveDate, setLeaveDate] = useState('singleLeave');

    const { showSuccess, showError } = useMessage();

    const fetchLeaveType = useCallback(async () => {
        try {
            const response = await axios.get('/employee/leaves/types');

            const leavesTypeInfo = response.data.leaveTypes;

            setLeaveType(leavesTypeInfo);
        } catch (e) {
            console.warn(e);
        }
    }, [setLeaveType]);

    useEffect(() => {
        fetchLeaveType();
    }, [fetchLeaveType]);

    const handlers = useForm(
        useMemo(
            () => ({
                leaveType: { required: true },
                reason: { required: true },
            }),
            []
        ),
        { Input: TextField }
    );

    function isWeekday(date) {
        const day = date?.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        return day >= 1 && day <= 5; // Monday to Friday
    }

    const selectedDays = useMemo(
        function () {
            const startDate = new Date(date.from?.$d);
            const endDate = new Date(date.to?.$d);

            if (leaveDate === 'singleLeave' && date.from?.$d) return 1;

            let selectedDays = 0;
            let currentDate = new Date(startDate);

            while (currentDate <= endDate) {
                selectedDays++;
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return selectedDays;
        },
        [date.from?.$d, date.to?.$d, leaveDate]
    );

    const leaveDays = useMemo(
        function () {
            const startDate = new Date(date.from?.$d);
            const endDate = new Date(date.to?.$d);

            if (leaveDate === 'singleLeave' && isWeekday(date.from?.$d)) return 1;

            let leaveDays = 0;
            let currentDate = new Date(startDate);

            while (currentDate <= endDate) {
                if (isWeekday(currentDate)) leaveDays++;
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return leaveDays;
        },
        [date.from?.$d, date.to?.$d, leaveDate]
    );

    console.log({ leaveDays, selectedDays });

    const changeHandlerReason = e => {
        const { name, value } = e.target;
        handlers.setValues({ [name]: value });
    };

    const onSubmit = response => {
        const { success } = response.data;

        if (success) showSuccess('Leave apply successfully');
        handleClose();
        fetchLeaves();
    };

    const onError = e => {
        handleAxiosError(e, showError);
    };

    return (
        <Card
            sx={{
                maxWidth: 500,
                width: '100%',

                p: 2,
                maxHeight: '85vh',
                boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                borderRadius: '8px',
                m: 2,
                my: 8,

                overflowY: 'auto',
                overflowX: 'hidden',
            }}>
            <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
                <Typography variant='h6'>Apply Leaves</Typography>
                <IconButton onClick={handleClose} size='small'>
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* apply leaves */}

            <Box>
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    onError={onError}
                    action='/employee/leaves'
                    final={values => ({
                        ...values,
                        dates:
                            leaveDate === 'singleLeave'
                                ? [
                                      {
                                          year: date.from.$y,
                                          month: date.from.$M + 1,
                                          day: date.from.$D,
                                      },
                                  ]
                                : [
                                      {
                                          year: date.from.$y,
                                          month: date.from.$M + 1,
                                          day: date.from.$D,
                                      },
                                      {
                                          year: date.to.$y,
                                          month: date.to.$M + 1,
                                          day: date.to.$D,
                                      },
                                  ],
                    })}
                    method='POST'>
                    <Box mb={2}>
                        <Typography sx={{ mb: 1 }} variant='body2'>
                            Leaves Dates
                        </Typography>
                        <FormControl fullWidth size='small'>
                            <Select value={leaveDate} onChange={e => setLeaveDate(e.target.value)}>
                                <MenuItem value='singleLeave'>Single Leave</MenuItem>
                                <MenuItem value='multipleLeave'>Multiple Leaves</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <Typography variant='body2'>Date Range</Typography>
                        <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                {leaveDate === 'singleLeave' ? (
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        disablePast={true}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                            },
                                        }}
                                        value={date.from}
                                        onChange={value =>
                                            setDate({
                                                ...date,
                                                from: value,
                                            })
                                        }
                                    />
                                ) : (
                                    <Stack
                                        width='100%'
                                        direction='row'
                                        spacing={2}
                                        alignItems='center'>
                                        <Box>
                                            <Typography
                                                variant='body2'
                                                color='text.tertiary'
                                                fontWeight={500}
                                                disablePast={true}
                                                gutterBottom>
                                                From
                                            </Typography>
                                            <DatePicker
                                                slotProps={{
                                                    textField: {
                                                        size: 'small',
                                                    },
                                                }}
                                                value={date.from}
                                                disablePast={true}
                                                onChange={value =>
                                                    setDate({
                                                        ...date,
                                                        from: value,
                                                    })
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <Typography
                                                variant='body2'
                                                color='text.tertiary'
                                                fontWeight={500}
                                                gutterBottom>
                                                To
                                            </Typography>
                                            <DatePicker
                                                fullWidth
                                                slotProps={{
                                                    textField: {
                                                        size: 'small',
                                                    },
                                                }}
                                                value={date.to}
                                                disablePast={true}
                                                onChange={value =>
                                                    setDate({
                                                        ...date,
                                                        to: value,
                                                    })
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                )}
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                    <Box>
                        <Typography variant='body2' sx={{ mb: 1 }}>
                            Leaves Types
                        </Typography>

                        <Select
                            size='small'
                            name='leaveType'
                            fullWidth
                            displayEmpty
                            value={handlers.values.leaveType}
                            onChange={changeHandlerReason}>
                            {leaveTypes?.map((leave, i) => (
                                <MenuItem value={leave._id} key={i}>
                                    {leave.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box my={1}>
                        <Typography sx={{ mb: 1 }} variant='body2'>
                            Reason
                        </Typography>
                        <Input name='reason' fullWidth multiline rows={2} placeholder='Reason' />
                    </Box>
                    <Box>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography>
                                Selected days:{' '}
                                {leaveDate === 'singleLeave' && selectedDays
                                    ? 1
                                    : date.from?.$d && date.to?.$d
                                    ? selectedDays
                                    : 'Not Available'}
                            </Typography>
                            <Typography>
                                Leave days:{' '}
                                {leaveDate === 'singleLeave' && date.from?.$d
                                    ? leaveDays
                                    : date.from?.$d && date.to?.$d
                                    ? leaveDays
                                    : 'Not Available'}
                            </Typography>
                        </Stack>
                        {selectedDays > leaveDays && leaveDate === 'multipleLeave' && (
                            <Typography variant='body2' mt={1}>
                                Your leave is for {leaveDays} days, and the remaining days are
                                either holidays or weekends.
                            </Typography>
                        )}

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                mt: 2,
                            }}>
                            <Button
                                variant='outlined'
                                onClick={handleClose}
                                sx={{
                                    mx: 1,
                                }}>
                                Close
                            </Button>
                            <Submit
                                loaderProps={{
                                    color: 'primary',
                                    size: 20,
                                }}>
                                {loader => (
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        disabled={Boolean(loader) || !Boolean(leaveDays)}
                                        endIcon={loader}>
                                        Apply
                                    </Button>
                                )}
                            </Submit>
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Card>
    );
};

export default Index;
