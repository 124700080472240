import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DataGrid from '../../components/DataGrid';
import axios from 'axios';
import useModal from '../../hooks/useModal';
import AddExpenses from './AddExpenses';

const columns = [
    {
        field: 'id',
        headerName: 'S.No.',
        sortable: false,
        width: 100,
        valueGetter: params => `${params.row.id + 1}`,
    },
    {
        field: 'title',
        headerName: 'Title',
        sortable: false,
        width: 200,
    },
    {
        field: 'purchasePlace',
        headerName: 'Purchase from',
        sortable: false,
        width: 200,
    },
    {
        field: 'date',
        headerName: 'Date',
        type: 'date',
        align: 'left',
        width: 150,
        valueGetter: params => {
            const { year, month, day } = params.row.dateOfPurchase;
            return new Date(year, month, day);
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 120,
    },
    {
        field: 'currency',
        headerName: 'Currency',
        width: 120,
    },
    {
        field: 'status',
        headerName: 'Approved Status',
        width: 300,
    },
];

const Index = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const { state, closeModal, openModal } = useModal();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);

    const fetchExpenses = useCallback(async () => {
        setLoading(true);

        console.log('fetching....');
        setRows([]);

        try {
            const response = await axios.get(
                `/employee/expenses/?page=${paginationModel.page + 1 || 1}&pageSize=${
                    paginationModel.pageSize
                }`
            );
            const { expenses, pageData } = response.data;

            const rows = expenses.map((expense, index) => ({
                ...expense,
                id: index,
                amount: expense.price.amount,
                currency: expense.price.currency,
            }));

            setRows(rows);
            setRowCount(pageData.totalData || 0);
        } catch (e) {
            console.warn(e);
        } finally {
            setLoading(false);
        }
    }, [setRows, paginationModel]);

    useEffect(() => {
        fetchExpenses();
    }, [fetchExpenses]);

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Expenses</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box mx={2}>
                            <Button variant='contained' onClick={openModal}>
                                Add Expenses
                            </Button>
                        </Box>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={4}>
                <Card elevation={0} sx={{ position: 'relative' }}>
                    <CardContent>
                        <Box
                            sx={{
                                borderLeftWidth: '5px',
                                borderLeftColor: 'primary.main',
                                borderLeftStyle: 'solid',
                                height: '40px',
                                width: '5px',
                                position: 'absolute',

                                left: 1,
                            }}></Box>
                        <Typography variant='h6' sx={{ mb: 3 }}>
                            My Expenses Summary
                        </Typography>

                        <DataGrid
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            paginationMode='server'
                            pageSizeOptions={[5, 10, 50, 100]}
                            autoHeight
                            disableSelectionOnClick
                            loading={loading}
                            rowCount={rowCount}
                        />
                    </CardContent>
                </Card>
            </Box>

            <Modal
                open={state}
                onClose={closeModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <AddExpenses closeModal={closeModal} refresh={fetchExpenses} />
                </>
            </Modal>
        </>
    );
};

export default Index;
