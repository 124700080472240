import React, { useEffect, useState } from 'react';
import { LineChart, axisClasses } from '@mui/x-charts';

const chartSetting = {
    // width: 700,
    height: 502,

    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'rotate(-90deg) translate(0px, -20px)',
        },
    },
};

const dataPrototype = {
    1: {
        Attendance: 0,
        Project: 0,
        month: 'Jan',
    },
    2: {
        Attendance: 0,
        Project: 0,
        month: 'Feb',
    },
    3: {
        Attendance: 0,
        Project: 0,
        month: 'Mar',
    },
    4: {
        Attendance: 0,
        Project: 0,
        month: 'Apr',
    },
    5: {
        Attendance: 0,
        Project: 0,
        month: 'May',
    },
    6: {
        Attendance: 0,
        Project: 0,
        month: 'Jun',
    },
    7: {
        Attendance: 0,
        Project: 0,
        month: 'Jul',
    },
    8: {
        Attendance: 0,
        Project: 0,
        month: 'Aug',
    },
    9: {
        Attendance: 0,
        Project: 0,
        month: 'Sept',
    },
    10: {
        Attendance: 0,
        Project: 0,
        month: 'Oct',
    },
    11: {
        Attendance: 0,
        Project: 0,
        month: 'Nov',
    },
    12: {
        Attendance: 0,
        Project: 0,
        month: 'Dec',
    },
};

const valueFormatter = value => `${value}`;
const DashboardBarChart = ({ projects, attendance }) => {
    const [dataSet, setDataSet] = useState(Object.values(dataPrototype));

    useEffect(() => {
        if (Array.isArray(projects)) {
            projects.forEach(project => {
                dataPrototype[project._id] = {
                    ...dataPrototype[project._id],
                    Project: project.project,
                };
            });
        }
    }, [projects]);

    useEffect(() => {
        if (Array.isArray(attendance)) {
            attendance.forEach(att => {
                dataPrototype[att._id] = { ...dataPrototype[att._id], Attendance: att.attendance };
            });
        }
    }, [attendance]);

    useEffect(() => {
        setDataSet(Object.values(dataPrototype));
    }, [projects, attendance]);

    return (
        <>
            <LineChart
                dataset={dataSet}
                xAxis={[
                    {
                        scaleType: 'band',
                        dataKey: 'month',
                        categoryGapRatio: 0.4,
                    },
                ]}
                series={[
                    { dataKey: 'Project', label: 'Project', valueFormatter },
                    {
                        dataKey: 'Attendance',
                        label: 'Attendance',
                        valueFormatter,
                    },
                ]}
                {...chartSetting}
            />
        </>
    );
};

export default DashboardBarChart;
