import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, LinearProgress } from '@mui/material';

const columns = [
    { id: 'Date', label: 'Date', minWidth: 130 },
    { id: 'Status', label: 'Status', minWidth: 100 },
    {
        id: 'Clock_In',
        label: 'Clock In',
        minWidth: 115,
        align: 'center',
    },
    {
        id: 'Clock_In_Date',
        label: 'Clock In Date',
        minWidth: 115,
        align: 'center',
    },
    {
        id: 'Clock_Out',
        label: 'Clock Out',
        minWidth: 115,
        align: 'center',
    },
    {
        id: 'Clock_Out_Date',
        label: 'Clock Out Date',
        minWidth: 115,
        align: 'center',
    },
    {
        id: 'Progress',
        label: 'Progress',
        minWidth: 120,
        align: 'center',
    },
    {
        id: 'Hours',
        label: 'Hours',
        minWidth: 110,
        align: 'center',
    },
];

const AttendenceTable = ({ attendanceData }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attendanceData ? (
                            attendanceData?.map((data) => {
                                const time = new Date(data.clockInTime);
                                let ClockInTime = time.toLocaleTimeString();
                                let ClockInDate =
                                    time.toLocaleDateString('en-GB');

                                const time2 = data.clockOutTime
                                    ? new Date(data.clockOutTime)
                                    : 'You are not clocked out';
                                let ClockOutDate = data.clockOutTime
                                    ? time2.toLocaleDateString('en-GB')
                                    : 'You are not clocked out';
                                let ClockOutTime = data.clockOutTime
                                    ? time2.toLocaleTimeString()
                                    : 'You are not clocked out';
                                // millisecond

                                const timeDifference = time2 - time;
                                // hour,min,second
                                const hours = Math.floor(
                                    timeDifference / (1000 * 60 * 60)
                                );
                                const minutes = Math.floor(
                                    (timeDifference % (1000 * 60 * 60)) /
                                        (1000 * 60)
                                );

                                // Format the result
                                const result = data.clockOutTime
                                    ? `${hours}h:${minutes}m`
                                    : 'N / A';

                                // progress bar
                                let h = hours * 3600;
                                let m = minutes * 60;
                                let total = h + m;

                                const value = data.clockOutTime
                                    ? (total * 100) / 28800
                                    : 0;

                                return (
                                    <TableRow
                                        hover
                                        role='checkbox'
                                        tabIndex={-1}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {data.clockInDate.day}-
                                            {data.clockInDate.month}-
                                            {data.clockInDate.year}
                                        </TableCell>
                                        <TableCell component='th' scope='row'>
                                            {data.status}
                                        </TableCell>

                                        <TableCell
                                            component='th'
                                            scope='row'
                                            align='center'
                                        >
                                            {ClockInTime}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            align='center'
                                        >
                                            {ClockInDate}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            align='center'
                                        >
                                            {ClockOutTime
                                                ? ClockOutTime
                                                : 'You are not clocked out'}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            align='center'
                                        >
                                            {ClockOutDate}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            align='center'
                                        >
                                            <LinearProgress
                                                variant='determinate'
                                                value={
                                                    value > 100 ? 100 : value
                                                }
                                                color='success'
                                                sx={{
                                                    textAlign: 'center',
                                                    height: '8px',
                                                    borderRadius: '20px',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                            align='center'
                                        >
                                            {result}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <Box>
                                <TableCell
                                    component='th'
                                    scope='row'
                                    align='center'
                                >
                                    Loading
                                </TableCell>
                            </Box>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={attendanceData ? attendanceData?.length : '0'}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default AttendenceTable;
