import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './utils/axios';

//pages
import Header from './layouts/Header';
import Dashboard from './pages/Dashboard';
import Attendance from './pages/Attendance';
import MyLeaves from './pages/MyLeaves';
import Payslips from './pages/Payslips';
import Expenses from './pages/Expenses';
import Profile from './pages/Profile';
import OnBording from './pages/Boarding/OnBording';
import Notice from './pages/Notice/Notice';
import Project from './pages/Projects/Project';
import AuthRouter from './router/AuthRouter';
import AuthorizationProvider from './hooks/Authorize';
import './index.css'
import ChatPage from './pages/Chat/ChatPage';

const App = () => {
    return (
        <Header>
            <AuthRouter>
                <Routes>
                    <Route path='/' element={<AuthorizationProvider role='employee' />}>
                        <Route index element={<Dashboard />} />
                        <Route path='attendance' element={<Attendance />} />
                        <Route path='my-leaves' element={<MyLeaves />} />
                        <Route path='payslips' element={<Payslips />} />
                        <Route path='expenses' element={<Expenses />} />
                        <Route path='profile' element={<Profile />} />
                        <Route path='notice' element={<Notice />} />
                        <Route path='projects' element={<Project />} />
                        <Route path='/chat' element={<ChatPage />} />
                    </Route>
                    <Route path='/on-boarding' element={<OnBording />} />
                   
                </Routes>
            </AuthRouter>
        </Header>
    );
};

export default App;
